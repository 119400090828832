<template>
  <v-dialog
    id="cu-workspace-dialog"
    v-model="CUDialogWS"
    :retain-focus="false"
    max-width="700"
    persistent
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        v-if="type == 'new'"
        light
        color="white"
        v-bind="attrs"
        v-on="on"
        @click.prevent="newWorkspaceEvent"
      >
        ADD NEW
        <v-icon right>
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
        v-bind="attrs"
        v-on="on"
        @click.prevent="modifyNewWorkspaceEvent"
      >
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <workspace-stepper
      :workspace-disabled="workspaceDisabled"
      :disable-layers="disableLayers"
      @closeDialogEvent="closeDialogEvent"
      @setWorkspaceDisabled="setWorkspaceDisabled($event)"
      @setDisableLayers="setDisableLayers($event)"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    WorkspaceStepper: () => import('@/components/policy/WorkspaceStepper')
  },
  props: {
    type: {
      default: '',
      type: String
    },
    ws: {
      default: null,
      type: Object
    }
  },
  data: () => {
    return {
      CUDialogWS: false,
      disableLayers: true,
      workspaceDisabled: false
    };
  },
  computed: {
    ...mapState(['geoserver_data'])
  },
  methods: {
    ...mapActions([
      'storeWorkspaces',
      'storeIsModifingWorkspace',
      'storeWorkspace',
      'storeDBLayers',
      'storeWSLayers'
    ]),
    async newWorkspaceEvent() {
      this.closeDialogEvent();
      this.geoserver_data.DBlayers = [];
      this.geoserver_data.chosenLayers = [];
      await this.storeWorkspaces();
      this.storeIsModifingWorkspace(false);
      const wsToSave = {
        workspace_name: '',
        n_calls: -1,
        start_time: '',
        stop_time: '',
        date_type: 'static',
        policy_id: 0
      };

      await this.storeWorkspace(wsToSave);
      this.workspaceDisabled = false;
      this.disableLayers = true;
      this.CUDialogWS = true;
    },
    async modifyNewWorkspaceEvent() {
      this.storeWorkspaces();
      this.closeDialogEvent();
      this.storeIsModifingWorkspace(true);
      const wsToSave = {
        workspace_name: this.ws.workspace_name,
        n_calls: this.ws.n_calls,
        start_time: this.ws.start_time,
        stop_time: this.ws.stop_time,
        date_type: this.ws.date_type,
        policy_id: this.$store.state.customerCRMID.policy_id
      };

      await this.storeWorkspace(wsToSave);
      await this.storeWSLayers(this.ws.workspace_name);
      await this.storeDBLayers();
      this.workspaceDisabled = true;
      this.disableLayers = false;
      this.CUDialogWS = true;
    },
    async closeDialogEvent() {
      const wsToSave = {
        workspace_name: '',
        n_calls: -1,
        start_time: '',
        stop_time: '',
        date_type: 'static',
        policy_id: 0
      };

      await this.storeWorkspace(wsToSave);
      this.CUDialogWS = false;
    },
    setDisableLayers(val) {
      this.disableLayers = val;
    },
    setWorkspaceDisabled(val) {
      this.workspaceDisabled = val;
    }
  }
};
</script>

<style lang="scss" scoped></style>
